import { ChangeDetectionStrategy, Component } from '@angular/core';
// import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
@Component({
	selector: 'nform-object-label',
	templateUrl: './label.component.html',
	styleUrls: ['./label.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})

export class NFormLabelComponent
	extends FieldType<NFormObjectFormlyFieldConfig>
	// implements OnInit
{

	static nFormObjectName = 'nform-label';

	// formControl: UntypedFormControl;

	// ngOnInit(){
	// 	this.setPlaceholder();
	// 	this.setMask();
	// 	this.setHasCharCount();
	// }

}
