<box-drop-files
	layout="column"
	[disabled]="
			!appDeviceTypeService.device.desktop
			||
			formState.disabled
			||
			nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.media_waiting
			||
			nformNonuserService.apiData.form_data?.custom_form_status === eNFormAPIJsonFormStatus.DONE
	"
	accept="image"
	translateMaxAllowedOne="Route.NForm.PHOTO.DragAndDropMaxAllowed"
	(onFilesDrop)="onFilesDrop($event)"
>

	<p
		class="mat-body"
	>
		<span
			*ngIf="field.data.enumarated"
		>
			{{tabControl.tabs[tabControl.current-1].tabNumber}}.{{field.data.enumarated}}.
		</span>

		{{ to.label }}
		<span
			*ngIf="to.required"
			theme-palette-hue-fg="warn"
		>
			*
		</span>
	</p>

	<div
		layout="row"
		layout-align="center center"
	>
		<a
			*ngIf="fileUploadData"
			[href]="fileUploadData.generatedImageSanatizedUrl"
			target="_blank"
		>
			<img
				[src]="fileUploadData.generatedImageSanatizedUrl"
				class="photo"
			>
		</a>
	</div>

	<!-- webcam snapshot -->
	<!-- <button
		*ngIf="field.data.enumarated !== 2"
		type="button"
		layout-align="left"
		mat-raised-button
		color="primary"
		(click)="setValue()"
		class="btn-webcam"
	>
		<mat-icon svgIcon="camera"></mat-icon>
		<span *ngIf="!fileUploadData">{{'Components.NForm.PHOTO.button-add-photo' | transloco}}</span>
		<span *ngIf="fileUploadData">{{'Components.NForm.PHOTO.button-change-photo' | transloco}}</span>
	</button> -->

	<button
		*ngIf="
			nformNonuserService.apiData.form_data?.custom_form_status === eNFormAPIJsonFormStatus.DONE
			&&
			(
				nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.media_waiting
				||
				!nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.media_waiting
				&&
				!nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.data_value
			)
		"
		mat-raised-button
		color="primary"
		disabled
	>
		{{
			(
				nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.media_waiting
				? 'Route.NForm.PHOTO.waitingProcess'
				: 'Route.NForm.PHOTO.noPhoto'
			) | transloco
		}}
	</button>

	<div
		*ngIf="!field.props?.readonly"
		layout-xs="column"
		layout-gt-xs="row"
		layout-margin
		layout-align="space-around"
		no-margin
	>

		<input
			id="photo-file-{{field.data.enumarated}}"
			type="file"
			accept="image/*"
			[attr.capture]="field.data.custom_form_data_content.gallery === '0' ? '' : null"
			(change)="handleFileInput($event)"
			class="inputfile"
			[disabled]="formState.disabled"
		>

		<button
			*ngIf="fileUploadData && fileUploadData.fail"
			type="button"
			flex
			layout="column"
			mat-raised-button
			color="primary"
			class="btn-webcam"
			(click)="retry()"
		>
			<div
				layout="row"
				layout-align="center center"
			>
				<mat-icon flex="none" svgIcon="replay"></mat-icon>
				<span>{{'Components.NForm.PHOTO.button-retry' | transloco}}</span>
			</div>
		</button>

		<button
			*ngIf="!fileUploadData || fileUploadData.done || fileUploadData.progress"
			type="button"
			flex
			layout="column"
			mat-button
			color="primary"
			class="btn-webcam"
			[ngClass]="{
				'mat-raised-button': !fileUploadData,
				'mat-stroked-button': !!fileUploadData
			}"
			[disabled]="formState.disabled || (fileUploadData && fileUploadData.inProgress)"
			(click)="setTouched()"
		>
			<label
				for="photo-file-{{field.data.enumarated}}"
			>
				<div
					*ngIf="!fileUploadData || (fileUploadData && !fileUploadData.inProgress)"
					layout="row"
					layout-align="center center"
				>
					<mat-icon svgIcon="camera"></mat-icon>
					<span *ngIf="!fileUploadData">{{'Components.NForm.PHOTO.button-add-photo' | transloco}}</span>
					<span *ngIf="fileUploadData">{{'Components.NForm.PHOTO.button-change-photo' | transloco}}</span>
				</div>
				<div
					*ngIf="fileUploadData && fileUploadData.inProgress"
					layout="row"
					layout-align="center center"
				>
					<span no-padding>{{fileUploadData.progress}}</span>
				</div>
			</label>
			<loading-spinner
				*ngIf="fileUploadData && fileUploadData.inProgress"
				size="36"
				overlay-fill="no-translucid"
			></loading-spinner>
		</button>

		<button
			*ngIf="fileUploadData && !fileUploadData.inProgress"
			type="button"
			flex
			layout="column"
			mat-stroked-button
			color="warn"
			class="btn-webcam"
			(click)="delete()"
		>
			<div
				layout="row"
				layout-align="center center"
			>
				<mat-icon flex="none" svgIcon="delete_forever"></mat-icon>
				<span>{{'Components.NForm.PHOTO.button-delete' | transloco}}</span>
			</div>
		</button>

	</div>

	<div
		*ngIf="to.required"
		class="mat-mdc-form-field-subscript-wrapper"
		theme-palette-hue-fg="warn"
	>
		{{'Form.ValidationMessage.required' | transloco}}
	</div>

</box-drop-files>
