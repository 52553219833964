import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { FieldType } from '@ngx-formly/material';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { ObjectExtraInfoService } from '../../object-wrapper/object-extra-info/object-extra-info.service';
import { getThousandSeparator } from 'src/app/helpers/intl';
import { NformNonuserService } from '../../nform-nonuser.service';
@Component({
	selector: 'nform-object-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})

export class NFormCheckboxComponent
	extends FieldType<NFormObjectFormlyFieldConfig>
	implements OnInit
{

	static nFormObjectName = 'nform-checkbox';

	_formControl: UntypedFormControl;

	thousandSeparator = getThousandSeparator(this.translocoService.getActiveLang());

	tabControl = this.nformNonuserService.tabsControl;

	constructor(
		private translocoService: TranslocoService,
		private objectExtraInfoService: ObjectExtraInfoService,
		protected nformNonuserService: NformNonuserService
	){
		super();
	}

	ngOnInit(){
		// this.field.data.require_photo_on_nc = 0;

		this._formControl = this.formControl as FormControl<any>;
		setTimeout(() => {
			if(this.field.props?.readonly){
				this._formControl.disable();
			}
		})
	}

	checkFormNC(val) {
		this.objectExtraInfoService.checkFormNC(val, this.field);
	}

	// get type() {
	// 	// return 'number';
	// 	return this.to.type || 'text';
	// }
}


