import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FileUploadData } from 'src/app/api-services/portal/upload-aws-file-webapp.service';
import { FileUploadObservables } from 'src/app/components/media-recorder/media-recorder.component';
import { NFormPendingFilesEnum } from '../../nform-nonuser.service';

@Injectable({
	providedIn: 'root'
})
export class NFormMediaRecorderService {

	fileUploadObservables: {
		[prop: string]: FileUploadObservables;
	} = {};

	private pendingFiles = new Subject();

	addFileUploadObservables(
		key: string,
		fileObservable: FileUploadObservables
	){

		this.fileUploadObservables[key] = fileObservable;

		fileObservable.observable.subscribe(fileUploadData => {
			if(fileUploadData.fail || fileUploadData.done){
				if(fileUploadData.fail){
					this.notifyPendingFilesError(fileUploadData)
				}
				this.notifyPendingFilesDone();
			}
		});

	}

	subscribeForPendingFiles(): Subject<any> {
		return this.pendingFiles;
	}

	hasPendingFiles(): boolean {
		return Object.entries(this.fileUploadObservables)
			.filter(entry => entry[1].data.inProgress).length > 0;
	}

	private notifyPendingFilesDone(){
		if(
			!this.hasPendingFiles()
			&&
			Object.entries(this.fileUploadObservables)
				.filter(entry => entry[1].data.fail).length === 0
		){
			this.pendingFiles.next(true);
		}
		this.notifyAllPendingFilesComplete();
	}

	private notifyPendingFilesError(fileUploaData: FileUploadData){
		this.pendingFiles.next(fileUploaData);
		this.notifyAllPendingFilesComplete();
	}

	private notifyAllPendingFilesComplete(){
		if(
			Object.entries(this.fileUploadObservables)
				.filter(entry => entry[1].data?.inProgress).length === 0
		){
			this.pendingFiles.next(NFormPendingFilesEnum.pendingFilesComplete);
		}
	}

	reset() {

		Object.keys(this.fileUploadObservables).forEach(
			key => {
				if(this.fileUploadObservables[key].data?.fileUploadSubscription){
					this.fileUploadObservables[key].data?.fileUploadSubscription.unsubscribe();
				}
				delete this.fileUploadObservables[key];
			}
		);

		this.fileUploadObservables = {};

	}

}
