import { NFormAPIData, NFormAPIJsonField } from 'src/app/api-services/portal/nform-nonuser.interface';
import { NFormCharComponent } from './char/char.component';
import { NFormCheckboxComponent } from './checkbox/checkbox.component';
import { NFormLabelComponent } from './label/label.component';
import { NFormDateComponent } from './date/date.component';
import { NFormHourComponent } from './hour/hour.component';
import { NFormNumberComponent } from './number/number.component';
import { NFormPhotoComponent } from './photo/photo.component';
import { NFormRatingbarComponent } from './ratingbar/ratingbar.component';
import { NFormRatingimageComponent } from './ratingimage/ratingimage.component';
import { NFormComboboxComponent } from './combobox/combobox.component';
import { NFormPictureComponent } from './picture/picture.component';
import { NFormMediaRecorderComponent } from './mediarecorder/mediarecorder.component';

function isFieldRequired(field: NFormAPIJsonField){
	return field.required === 1;
}

// CHAR
export function nFormCHARFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormCharComponent.nFormObjectName,
		templateOptions: {
			required: isFieldRequired(field),
			label: translate.value,
			floatLabel: 'paragraph',
			autocomplete: true
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}

// NUMBER
export function nFormNUMBERFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormNumberComponent.nFormObjectName,
		templateOptions: {
			required: isFieldRequired(field),
			label: translate.value,
			floatLabel: 'paragraph',
			autocomplete: true
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}

// PHOTO
export function nFormPHOTOFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormPhotoComponent.nFormObjectName,
		templateOptions: {
			required: isFieldRequired(field),
			label: translate.value
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}

// CHECKBOX
export function nFormCHECKBOXFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormCheckboxComponent.nFormObjectName,
		templateOptions: {
			required: true,
			label: translate.value
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}

// RATINGIMAGE
export function nFormRATINGIMAGEFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormRatingimageComponent.nFormObjectName,
		templateOptions: {
			required: true,
			label: translate.value
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}

// RATINGBAR
export function nFormRATINGBARFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormRatingbarComponent.nFormObjectName,
		templateOptions: {
			required: true,
			label: translate.value
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}

// LABEL
export function nFormLABELFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		// key: field.custom_form_seq,
		type: NFormLabelComponent.nFormObjectName,
		templateOptions: {
			label: translate.value
		},
		nform,
		data: field
	};
}

// DATE
export function nFormDATEFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormDateComponent.nFormObjectName,
		templateOptions: {
			required: isFieldRequired(field),
			label: translate.value,
			floatLabel: 'paragraph',
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}

// HOUR
export function nFormHOURFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormHourComponent.nFormObjectName,
		templateOptions: {
			required: isFieldRequired(field),
			label: translate.value,
			floatLabel: 'paragraph',
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}

// COMBOBOX
export function nFormCOMBOBOXFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormComboboxComponent.nFormObjectName,
		templateOptions: {
			required: isFieldRequired(field),
			label: translate.value,
			floatLabel: 'paragraph',
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}

// PICTURE
export function nFormPICTUREFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormPictureComponent.nFormObjectName,
		templateOptions: {
			required: isFieldRequired(field),
			label: translate.value
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}

// MEDIA RECORDER
export function nFormMEDIARECORDERFormlyFieldConstructor(
	nform: NFormAPIData,
	field: NFormAPIJsonField,
	translate: any
){
	return {
		key: field.custom_form_seq,
		type: NFormMediaRecorderComponent.nFormObjectName,
		templateOptions: {
			required: isFieldRequired(field),
			label: translate.value
		},
		nform,
		data: field,
		expressionProperties: {
			'templateOptions.disabled': 'formState.disabled',
		}
	};
}
