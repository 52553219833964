<div
	layout="column"
	[ngClass]="{
		'ng-touched': formControl.touched,
		'ng-invalid': formControl.invalid,
		'ng-untouched': formControl.untouched
	}"
	position-relative
>

	<p
		class="mat-body"
	>
		<span
			*ngIf="field.data.enumarated"
		>
			{{tabControl.tabs[tabControl.current-1].tabNumber}}.{{field.data.enumarated}}.
		</span>

		{{ to.label }}
		<span
			*ngIf="to.required"
			theme-palette-hue-fg="warn"
		>
			*
		</span>
	</p>

	@if (
		!this.field.props?.readonly
		||
		this.field.props?.readonly
		&&
		nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.data_value
	) {
		<div
			layout="row"
			layout-align="center center"
		>
			<media-recorder
				[allowRecord]="!this.field.props?.readonly"
				[mediaURL]="nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.data_value || null"
				[mediaType]="mediaType"
				[recordingTimeSeconds]="field.data?.custom_form_data_content?.max_duration || 15"
				[fileName]="fileName"
				[fileUploadObservables]="nFormMediaRecorderService.fileUploadObservables[fileUploadObservablesKey]"
				[disabled]="formState.disabled"
				(onStartFileUploadObservable)="onStartFileUploadObservable(fileName, $event)"
				(onRecordCancel)="onRecordCancel($event)"
			></media-recorder>
		</div>
	}

	<button
		*ngIf="
			nformNonuserService.apiData.form_data?.custom_form_status === eNFormAPIJsonFormStatus.DONE
			&&
			(
				nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.media_waiting
				||
				!nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.media_waiting
				&&
				!nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.data_value
			)
		"
		mat-raised-button
		color="primary"
		disabled
		class="btn-waiting-no-media"
	>
		@if(nformNonuserService.mapOfNFormFieldsData?.[field.data.custom_form_seq]?.media_waiting){
			{{
				(
					mediaType === 'video'
					? 'Route.NForm.MediaRecorder.videoWaitingProcess'
					: 'Route.NForm.MediaRecorder.audioWaitingProcess'
				) | transloco
			}}
		}@else{
			{{
				(
					mediaType === 'video'
					? 'Route.NForm.MediaRecorder.noVideo'
					: 'Route.NForm.MediaRecorder.noAudio'
				) | transloco
			}}
		}
	</button>

	<div
		class="mat-mdc-form-field-subscript-wrapper"
		theme-palette-hue-fg="warn"
	>
		<mat-error>
			<formly-validation-message [field]="field"></formly-validation-message>
		</mat-error>
	</div>

	<div
		*ngIf="to.required"
		class="mat-mdc-form-field-subscript-wrapper fixed-required"
		theme-palette-hue-fg="warn"
	>
		{{'Form.ValidationMessage.required' | transloco}}
	</div>

</div>
