import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { UntypedFormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FieldType } from '@ngx-formly/material';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { ObjectExtraInfoService } from '../../object-wrapper/object-extra-info/object-extra-info.service';
import { NformNonuserService } from '../../nform-nonuser.service';

@Component({
	selector: 'nform-object-ratingbar',
	templateUrl: './ratingbar.component.html',
	styleUrls: ['./ratingbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class NFormRatingbarComponent
	extends FieldType<NFormObjectFormlyFieldConfig>
	implements OnInit {

	static nFormObjectName = 'nform-ratingbar';

	eNFormRatingbarValues = NFormRatingbarValues;
	_formControl: UntypedFormControl;
	keyString: string;
	stepSize = 1;
	loading = true;

	tabControl = this.nformNonuserService.tabsControl;

	@ViewChild('matSliderInput') matSliderInput: ElementRef;

	constructor(
		private cdr: ChangeDetectorRef,
		private objectExtraInfoService: ObjectExtraInfoService,
		protected nformNonuserService: NformNonuserService
	) {
		super();
	}

	ngOnInit(){
		this._formControl = this.formControl as FormControl<any>;
		this.keyString = this.key.toString();
		this.setStepSize();
		this.initValue();
		this.loading = false;
		this.cdr.detectChanges();
	}

	setValue(val: number){
		this.formControl.setValue(val);
		this.objectExtraInfoService.checkFormNC(val, this.field);
	}

	applyBlurOnSlider() {
		this.matSliderInput.nativeElement.focus();
	}

	naChange(event: MatSlideToggleChange){
		if(event.checked){
			this.setValue(this.eNFormRatingbarValues.na);
		}else{
			this.setValue(parseInt(this.field.data.custom_form_data_content.min, 10));
		}
	}

	private initValue(){
		if(typeof this.model[this.keyString] != 'number'){
			this.formControl.setValue(parseInt(this.field.data.custom_form_data_content.min, 10));

			this.objectExtraInfoService.checkFormNC(this.formControl.value, this.field);
		}
	}

	private setStepSize(){
		this.stepSize = Math.floor(
			(
				parseInt(this.field.data.custom_form_data_content.max, 10)
				-
				parseInt(this.field.data.custom_form_data_content.min, 10)
			)
			/
			parseInt(this.field.data.custom_form_data_content.div, 10)
		);
	}
}

export enum NFormRatingbarValues {
	na = -1,
}
