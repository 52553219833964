import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
// import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FieldType } from '@ngx-formly/core';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { NformNonuserService } from '../../nform-nonuser.service';
import { NFormAPIJsonFormStatus, NFormObjects } from 'src/app/api-services/portal/nform-nonuser.interface';
import { AppDeviceTypeService } from 'src/app/app.device-type.service';
import { FileUploadObservables, NamoaMediaRecorderService } from 'src/app/components/media-recorder/media-recorder.component';
import { Subscription } from 'rxjs';
import { NFormMediaRecorderService } from './mediarecorder.service';

@Component({
	selector: 'nform-object-photo',
	templateUrl: './mediarecorder.component.html',
	styleUrls: ['./mediarecorder.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class NFormMediaRecorderComponent
	extends FieldType<NFormObjectFormlyFieldConfig>
	implements OnInit, OnDestroy
{

	eNFormAPIJsonFormStatus = NFormAPIJsonFormStatus;

	keyString: string;

	static nFormObjectName = 'nform-mediarecorder';
	fileInput: HTMLInputElement;

	tabControl = this.nformNonuserService.tabsControl;

	private fileUploadObservables: Subscription;
	private mediaRecorderInUseChangeSubcriber: Subscription;

	fileName = '';
	fileUploadObservablesKey = '';

	mediaType: 'audio' | 'video';

	constructor(
		public dialog: MatDialog,
		private cdr: ChangeDetectorRef,
		public nFormMediaRecorderService: NFormMediaRecorderService,
		private datePipe: DatePipe,
		private domSanitizer: DomSanitizer,
		public nformNonuserService: NformNonuserService,
		public appDeviceTypeService: AppDeviceTypeService,
		private namoaMediaRecorderService: NamoaMediaRecorderService,
	){
		super();
	}

	ngOnInit(){

		this.setMediaType();

		this.keyString = this.key.toString();

		this.setFileUploadObservablesKey();

		const fileUploadDataObservable = this.nFormMediaRecorderService
			.fileUploadObservables[this.fileUploadObservablesKey];

		if(fileUploadDataObservable){

			this.fileName = fileUploadDataObservable.data.file.name;

			if(!fileUploadDataObservable.data.fileURLTemp){
				fileUploadDataObservable.data.fileURLTemp = URL.createObjectURL(
					this.nFormMediaRecorderService.fileUploadObservables[this.fileUploadObservablesKey]
						.data.file
				);
			}

			this.fileUploadObservable(
				this.nFormMediaRecorderService.fileUploadObservables[this.fileUploadObservablesKey]
			);

		}else{

			this.fileName = this.fileUploadObservablesKey
				+ this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH.mm.ss.SSS')

		}

		this.mediaRecorderInUseChangeSubcriber = this.namoaMediaRecorderService
			.mediaRecorderInUseChange.subscribe(() => {
				console.log('mediaRecorderInUseChange');
				this.cdr.detectChanges();
			});

	}

	setMediaType(){
		this.mediaType = this.field.data.custom_form_data_type === NFormObjects.AUDIO
			? 'audio'
			: 'video'
	}

	ngOnDestroy(){
		if(this.fileUploadObservables){
			this.fileUploadObservables.unsubscribe();
		}
		if(this.mediaRecorderInUseChangeSubcriber){
			this.mediaRecorderInUseChangeSubcriber.unsubscribe();
		}
	}

	onStartFileUploadObservable(fileName: string, fileUploadObservables: FileUploadObservables){

		this.nFormMediaRecorderService.addFileUploadObservables(
			this.fileUploadObservablesKey,
			fileUploadObservables
		);

		this.fileUploadObservable(fileUploadObservables);

	}

	setFileUploadObservablesKey(){
		this.fileUploadObservablesKey = this.field.nform.customer_code
			+ '.' + this.field.nform.site_id
			+ '.' + this.field.nform.product_code
			+ '.' + this.field.nform.serial_code
			+ '.' + this.field.nform.form.custom_form_type
			+ '.' + this.field.nform.form.custom_form_code
			+ '.' + this.field.nform.form.custom_form_version
			+ '.' + this.field.data.custom_form_seq

	}

	fileUploadObservable(fileUploadObservables: FileUploadObservables){
		this.fileUploadObservables = fileUploadObservables.observable.subscribe(fileUploadData => {

			setTimeout(() => this.field.data.fileUploadData = fileUploadData);

			if(this.formControl.value !== fileUploadData.file.name){
				this.formControl.setValue(fileUploadData.file.name);
			}

			this.cdr.detectChanges();

			// workaround due to Formly on change strategy on push
			if(fileUploadObservables.data.done || fileUploadObservables.data.fail){
				setTimeout(() => {
					this.formControl.markAsTouched();
					this.formControl.updateValueAndValidity();
					this.cdr.detectChanges();
				})
			}

		})
	}

	onRecordCancel(fileName: string){
		this.formControl.reset();
		delete this.model[this.field.key as string];
		if(this.nFormMediaRecorderService.fileUploadObservables[this.fileUploadObservablesKey]){
			delete this.nFormMediaRecorderService.fileUploadObservables[this.fileUploadObservablesKey];
		}
		this.field.data.fileUploadData = null;
		this.formControl.updateValueAndValidity();
		this.cdr.detectChanges();
	}

	setTouched(){
		this.formControl.markAllAsTouched();
	}

	// delete(){
	// 	if(this.fileInput){
	// 		this.fileInput.value = null;
	// 	}
	// 	this.nformPhotoService.delete(this.fileUploadData);
	// 	this.setFileUploadData(null);
	// 	this.formControl.reset();
	// }

}
