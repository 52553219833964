import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NFormPictureComponent } from '../picture.component';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'nform-picture-img-grid-dialog',
	templateUrl: './img-grid-dialog.component.html',
	styleUrls: ['./img-grid-dialog.component.scss'],
	standalone: false
})
export class NFormPictureImgGridDialogComponent
{

	noGrid = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		@Inject(DOCUMENT) private document: Document,
		public dialog: MatDialog
	) {

		if(
			this.data.formlyField.field.data.custom_form_data_content.lines === '0'
			&&
			this.data.formlyField.field.data.custom_form_data_content.columns === '0'
		){
			this.noGrid = true;
		}

	}

	close(){
		this.dialog.closeAll();
		if(this.document.exitFullscreen){
			this.document.exitFullscreen().then(
				result => console.log(result),
				error => console.log(error)
			);
		}
	}

}

export interface DialogData {
	formlyField: NFormPictureComponent;
}
