import { Component, OnInit } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
	selector: 'nform-object-photo-webcam',
	templateUrl: './webcam.component.html',
	styleUrls: ['./webcam.component.scss'],
	standalone: false
})
export class NFormPhotoWebcamComponent implements OnInit {

	showWebcam = false;
	allowCameraSwitch = true;
	multipleWebcamsAvailable = false;
	deviceId: string;

	base64TrimmedURL: string;
	base64DefaultURL: string;

	videoOptions: MediaTrackConstraints = {
		width: {min: 1280, ideal: 1920},
		height: {min: 720, ideal: 1080}
		// width: {ideal: 1024},
		// height: {ideal: 576}
	};

	errors: WebcamInitError[] = [];

	// latest snapshot
	webcamImage: WebcamImage = null;

	// webcam snapshot trigger
	private trigger: Subject<void> = new Subject<void>();
	// switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
	private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

	constructor(
		// private domSanitizer: DomSanitizer,
		// private http: HttpClient
	){}

	ngOnInit(): void {
		WebcamUtil.getAvailableVideoInputs()
			.then((mediaDevices: MediaDeviceInfo[]) => {
				this.setDeviceId(mediaDevices);
				this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
			});
	}

	// enumerateDevices;
	setDeviceId(mediaDevices: MediaDeviceInfo[]){

		// navigator.mediaDevices.enumerateDevices()
		// 	.then((devices: MediaDeviceInfo[]) =>
		// 		this.enumerateDevices = devices.filter(device =>
		// 			device.kind === 'videoinput'
		// 		)
		// 	);

		if(mediaDevices.length){

			const cameraBack = mediaDevices.find(device => /back/.test(device.label));

			if(cameraBack){
				this.videoOptions.deviceId = cameraBack.deviceId;
			}else{
				this.videoOptions.deviceId = mediaDevices[mediaDevices.length-1].deviceId;
			}

			this.showWebcam = true;

		}

	}

	triggerSnapshot(): void {
		this.trigger.next();
	}

	toggleWebcam(): void {
		this.showWebcam = !this.showWebcam;
	}

	handleInitError(error: WebcamInitError): void {
		this.errors.push(error);
	}

	showNextWebcam(directionOrDeviceId: boolean|string): void {
		// true => move forward through devices
		// false => move backwards through devices
		// string => move to device with given deviceId
		this.nextWebcam.next(directionOrDeviceId);
	}

	rejectImage(){
		this.webcamImage = null;
	}

	handleImage(webcamImage: WebcamImage): void {
		console.log('received webcam image', webcamImage);
		this.webcamImage = webcamImage;
		this.base64TrimmedURL = webcamImage.imageAsBase64;
	}

	cameraWasSwitched(deviceId: string): void {
		console.log('active device: ' + deviceId);
		this.deviceId = deviceId;
	}

	get triggerObservable(): Observable<void> {
		return this.trigger.asObservable();
	}

	get nextWebcamObservable(): Observable<boolean|string> {
		return this.nextWebcam.asObservable();
	}

	// upload(uploadFileData: FileUploadData): Observable<any>{

	// 	const uploadData = new FormData();

	// 	uploadData.append(
	// 		'photo',
	// 		uploadFileData.file,
	// 		uploadFileData.file.name
	// 	);

	// 	return this.http.post(
	// 		'http://localhost:3000/api',
	// 		uploadData,
	// 		{
	// 			reportProgress: true,
	// 			observe: 'events',
	// 		}
	// 	)
	// 		.pipe(
	// 			map(event => {
	// 				switch (event.type) {
	// 					case HttpEventType.UploadProgress:
	// 						// console.log(Math.round(event.loaded * 100 / event.total));
	// 						uploadFileData.progress = Math.round(event.loaded * 100 / event.total);
	// 						break;
	// 					case HttpEventType.Response:
	// 						return event;
	// 				}
	// 			}),
	// 			catchError((error: HttpErrorResponse) => {
	// 				// file.inProgress = false;
	// 				console.log('error 1: ', error);
	// 				// return of(`${file.data.name} upload failed.`);
	// 				throw error;
	// 			})
	// 		);

	// }

}
