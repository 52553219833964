import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { NformNonuserService } from '../../nform-nonuser.service';
@Component({
	selector: 'nform-object-char',
	templateUrl: './char.component.html',
	styleUrls: ['./char.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})

export class NFormCharComponent
	extends FieldType<NFormObjectFormlyFieldConfig>
	implements OnInit
{

	static nFormObjectName = 'nform-char';

	hasCharCount = false;
	_formControl: UntypedFormControl;
	mask: string = null;

	tabControl = this.nformNonuserService.tabsControl;

	@ViewChild('autosize') autosize: CdkTextareaAutosize;

	ipt: HTMLTextAreaElement | HTMLInputElement;

	@ViewChild('ipt') set element(element: ElementRef) {

		if(
			element.nativeElement.constructor === HTMLTextAreaElement
			||
			element.nativeElement.constructor === HTMLInputElement
		) {
			this.ipt = element.nativeElement;
		}

	}

	/**
	 *
	 */
	constructor(
		protected nformNonuserService: NformNonuserService
	) {
		super();
	}

	ngOnInit(){
		this._formControl = this.formControl as FormControl<any>;
		this.setPlaceholder();
		this.setMask();
		this.setHasCharCount();
	}

	get type() {
		return this.to.type || 'text';
	}

	private setHasCharCount(){
		if(!this.mask){
			this.hasCharCount = !!this.field.data?.custom_form_data_size;
		}
	}

	private setPlaceholder(){

		const placeHolder = this.field.data?.custom_form_data_content?.value;

		if(placeHolder){
			this.field.templateOptions.placeholder = placeHolder;
		}else if(this.field.data?.custom_form_data_mask?.value){
			this.field.templateOptions.placeholder =
				this.field.data.custom_form_data_mask.value
					.replace(/\$/g,'1')
					.replace(/\#/g,'A');
		}

	}

	private setMask(){

		let mask = this.field.data?.custom_form_data_mask?.value;

		if(mask){
			mask = mask
				.replace(/\$/g,'0')
				.replace(/\#/g,'S');
		}

		this.mask = mask || null;

		// if(mask){
		// 	this
		// }

	}

}
